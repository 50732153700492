const cookieName = 'euCookieDirective';
const cookieExpiryDays = 365;

export function cookieDirective() {
    const el = document.getElementById("cookieDirective");

    if (el && !getCookie(cookieName)) {
        el.innerHTML = showWarning();
        el.style.display = "block";

        const okBtn = document.getElementById("agreeCookies");
        okBtn.addEventListener('click', () => {
            setCookie(cookieName, 1, cookieExpiryDays);
            el.style.display = "none";
            el.innerHTML = "";
        });
    }
}

function showWarning() {
    return `
<div class="container">
<div class="d-flex justify-content-between align-items-center">
    <p>
        <strong>We use cookies to help improve our site</strong>. If you continue, we'll assume that you're happy to accept our cookies.
        <a href="/Home/Terms">Find out more about cookies.</a>
    </p>
    <button id="agreeCookies" type="button" class="btn btn-light ">Accept</button>
</div>
</div>       
        `;
}

// Set cookie
function setCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
}

// Get cookie
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}