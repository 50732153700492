import * as bootstrap from 'bootstrap';
import {apiGet, apiPost, debounce} from './api';
import {cookieDirective} from './cookie_directive';

// Or import only needed plugins
import { Tooltip as Tooltip, Popover as Popover } from 'bootstrap';
// Or import just one
//import Alert as Alert from '../node_modules/bootstrap/js/dist/alert';

//import jquery from "jquery";
//export default (window.$ = window.jQuery = jquery);


/**
 * Create a global namespace for non-Angular code
 */
window.FEH = window.FEH || {};

// Refresh credit quota counters 
function updateCreditQuota() {
    apiGet("/api/account/credit-quota", function (data) {
        const r = JSON.parse(data);
        const quotas = r.payload;
        const statusEl = document.getElementById('creditStatus');
        const creditQuotaEl = document.getElementById('creditQuota');
        const creditQuotBadgeEl = document.getElementById('creditQuotaBadge');
        const creditOrgEl = document.getElementById('creditOrg');
        if (!!statusEl && !!creditQuotaEl && !!creditQuotaEl) {
            creditQuotaEl.innerHTML = quotas.quota;
            creditOrgEl.innerHTML = quotas.credits;
            if (quotas.quota) {
                creditQuotaEl.innerHTML = quotas.quota;
                creditQuotBadgeEl.style.display = "block";
            } else {
                creditQuotBadgeEl.style.display = "none";
            }
        }
    });
    setTimeout(updateCreditQuota, 60*1000);
}
updateCreditQuota();
cookieDirective();

// Enable tooltips
// example usage - <button type="button" className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="[Tooltip content]">Press me</button>
let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
})
